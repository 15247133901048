import { children, Show } from 'solid-js';
import dayjs from '@troon/dayjs';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Button } from '../forms/button';
import { Picture } from './picture';
import { Link } from './link';
import type { ComponentProps, JSXElement, ParentProps } from 'solid-js';

type Props = ParentProps<{
	date?: Date | string | number;
	hero?: Omit<ComponentProps<typeof Picture>, 'width' | 'height'> & { width?: number; height?: number };
	title: JSXElement;
	subtitle?: JSXElement;
	buttonLabel?: JSXElement;
	url: string;
	appearance?: 'vertical-only' | 'auto';
}>;

export function ArticleTile(props: Props) {
	const summary = children(() => props.children);
	const subtitle = children(() => props.subtitle);
	const button = children(() => props.buttonLabel);
	return (
		<article class="@container/article">
			<div
				class={twJoin(
					'group relative flex flex-col gap-x-6 gap-y-2',
					props.appearance !== 'vertical-only' && 'items-center @3xl/article:flex-row',
				)}
			>
				<div class="shrink-0 overflow-hidden rounded">
					<Show when={props.hero}>
						{(image) => (
							<Picture
								alt=""
								crop="entropy"
								{...image()}
								width={image().width ?? 480}
								height={image().height ?? 270}
								loading={image().loading ? 'eager' : 'lazy'}
								sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 95vw"
								class={twJoin(
									'size-full object-cover group-hover:scale-105  motion-safe:transition-transform motion-safe:duration-200',
									props.appearance !== 'vertical-only' && '@3xl/article:size-36',
								)}
							/>
						)}
					</Show>
				</div>
				<div
					class={twJoin(
						'flex w-full grow flex-col justify-center gap-y-2',
						props.appearance !== 'vertical-only' && '@3xl/article:gap-px',
					)}
				>
					<h1>
						<Link
							href={props.url}
							class="block text-lg font-semibold after:absolute after:inset-0 group-hover:text-brand-600 group-hover:underline"
						>
							{props.title}
						</Link>
					</h1>
					<Show when={subtitle()}>{subtitle()}</Show>
					<Show when={summary()}>
						<div class="[&_p]:line-clamp-2">{summary()}</div>
					</Show>
					<Show when={props.date}>
						{(date) => <time class="text-sm">{dayjs.tz(date()).format('MMMM D, YYYY')}</time>}
					</Show>
				</div>

				<Show when={button()}>
					<Button
						appearance="tertiary"
						as={Link}
						href={props.url}
						class="relative z-10 w-full whitespace-nowrap text-nowrap @3xl/article:size-fit @3xl/article:grow-0"
					>
						{button()}
					</Button>
				</Show>
			</div>
		</article>
	);
}
